import graphql from 'graphql-tag'

export const TD_GET_MATCH_UPS = graphql`
  query TD_GetMatchUps($filter: td_MatchUpFilterOptions, $sort: td_SortOrder, $pageArgs: td_PaginationArgs) {
    td_matchUps(filter: $filter, sort: $sort, pageArgs: $pageArgs) {
      items {
        id
        score {
          scoreString
        }
        sides {
          sideNumber
          players {
            person {
              id
              clubsparkId
              extensions {
                name
                value
              }
              standardGivenName
              standardFamilyName
            }
          }
        }
        winningSide
        providerMatchId
        ageCategoryCode
        surfaceCategory
        type
        matchUpFormat
        grade
        start
        end
        circuit
        roundNumber
        roundPosition
        roundName
        status
        divisionType
        drawType
        tieMatchUpId
        gender
        drawId
        tournament {
          name
        }
        worldTennisNumbers {
          personId
          confidence
          tennisNumber
          type
        }
      }
      totalItems
    }
  }
`