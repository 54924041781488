import { FC, SVGProps } from 'react'
import { FindGroup } from 'src/graphql-types/ita/FindGroup'
import { ListSeasons } from 'src/graphql-types/ita/ListSeasons'
import { PersonData_personById_groups } from 'src/graphql-types/ita/PersonData'
import { Td_personTournaments_td_person_tournaments_items_matchUps_items_sides, Td_personTournaments_td_person_tournaments_items_matchUps_items_sides_players, Td_personTournaments_td_person_tournaments_items_matchUps_items_worldTennisNumbers, Td_personTournaments_td_person_tournaments_items_matchUps_items_extensions } from 'src/graphql-types/ita/Td_personTournaments'
import { td_SideEnum, td_WorldTennisNumberTypeEnum } from 'src/graphql-types/ita/globalITATypes'
import { getConfidenceIcon, getConfidenceLevel } from 'src/utils/wtn'


export function getPlayerWtn(uaid: string, playerId: string | undefined, wtnType: td_WorldTennisNumberTypeEnum, worldTennisNumbers: (Td_personTournaments_td_person_tournaments_items_matchUps_items_worldTennisNumbers | null)[] | null): Td_personTournaments_td_person_tournaments_items_matchUps_items_worldTennisNumbers | undefined | null {
  return worldTennisNumbers?.find(
    wtn => wtn?.type === wtnType && ((uaid && wtn?.personId === uaid) || (playerId && wtn?.personId === playerId))
  )
}

export function findPlayerSide(uaid: string, playerId: string | undefined, sides: (Td_personTournaments_td_person_tournaments_items_matchUps_items_sides | null)[] | null): td_SideEnum | undefined {
  if (!sides) return

  for (const side of sides) {
    const sideNumber = side?.sideNumber

    if (!side?.players) return

    for (const player of side.players) {
      if ((uaid && player?.person?.externalID === uaid) || (playerId && player?.person?.id === playerId)) {
        return sideNumber
      }
    }
  }
}

export function getOpponents(uaid: string, playerId: string | undefined, sides: (Td_personTournaments_td_person_tournaments_items_matchUps_items_sides | null)[] | null): string | undefined {
  if (!sides) return

  const profilePlayerSide = findPlayerSide(uaid, playerId, sides,)

  if (!profilePlayerSide) return

  let oppPlayers: Td_personTournaments_td_person_tournaments_items_matchUps_items_sides_players[] = []

  for (const side of sides) {
    if (side?.sideNumber !== profilePlayerSide && side?.players) {
      oppPlayers = side?.players
    }
  }

  const opponentNames: string[] = []

  for (const player of oppPlayers) {
    const oppName = `${player?.person?.nativeGivenName} ${player?.person?.nativeFamilyName}`
    opponentNames.push(oppName)
  }

  const opponent1 = opponentNames[0] || ''
  const opponent2 = opponentNames[1] || ''
  const space = opponent2 ? ', ' : ''

  return opponent1 + space + opponent2
}

export function winLoss(uaid: string, playerId: string | undefined, sides: (Td_personTournaments_td_person_tournaments_items_matchUps_items_sides | null)[] | null, winningSide: td_SideEnum | null): string | undefined {
  const profilePlayerSide = findPlayerSide(uaid, playerId, sides)

  if (!profilePlayerSide) {
    return
  }
  return profilePlayerSide === winningSide ? 'W' : 'L'
}

export function getConfidence(uaid: string, playerId: string | undefined, wtnType: td_WorldTennisNumberTypeEnum, worldTennisNumbers: (Td_personTournaments_td_person_tournaments_items_matchUps_items_worldTennisNumbers | null)[] | null): string | FC<SVGProps<SVGSVGElement>> {
  const confidence = getPlayerWtn(uaid, playerId, wtnType, worldTennisNumbers)?.confidence

  const confidenceRatingLevel = confidence ? getConfidenceLevel(confidence) : undefined

  return confidenceRatingLevel ? getConfidenceIcon(confidenceRatingLevel) : ''
}

export function getOpponentTeam(extensions: (Td_personTournaments_td_person_tournaments_items_matchUps_items_extensions | null)[] | null, groupsData: FindGroup, seasonQueryData: ListSeasons, playerData: { groups: PersonData_personById_groups[]; }, matchStartDate: Date, matchEndDate: Date): string | null | undefined {

  if (!extensions || !extensions?.length) return undefined

  const awayTeamExtension = extensions?.find(extension => extension?.name === 'awayTeamId')
  const homeTeamExtension = extensions?.find(extension => extension?.name === 'homeTeamId')

  const awayTeam = groupsData?.findGroup?.results?.find(team => team?.groupId?.toUpperCase() === awayTeamExtension?.value?.toUpperCase())
  const homeTeam = groupsData?.findGroup?.results?.find(team => team?.groupId?.toUpperCase() === homeTeamExtension?.value?.toUpperCase())

  const matchSeasonData = seasonQueryData?.listSeasons?.find(seasonData => seasonData?.startDate <= matchStartDate && seasonData?.endDate >= matchEndDate)

  const seasonTeam = playerData?.groups?.find(group => group?.seasonId?.toUpperCase() === matchSeasonData?.id?.toUpperCase())
  const teamId = seasonTeam?.groupId

  if (!teamId) return undefined

  if (homeTeam?.groupId?.toUpperCase() === teamId?.toUpperCase()) {
    return awayTeam?.groupFullName || awayTeam?.groupName
  } else if (awayTeam?.groupId?.toUpperCase() === teamId?.toUpperCase()) {
    return homeTeam?.groupFullName || homeTeam?.groupName
  }

}