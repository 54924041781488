import React, { lazy, Suspense } from 'react'
import { H3 } from '@clubspark-react/clubspark-react-tools'
import { Divider, Grid } from '@material-ui/core'
import * as styles from './point-summary.module.less'
import theme from '../../styles/theme'

const ReactApexChart = lazy(() => import('react-apexcharts'))
const isBrowser = typeof window !== 'undefined'

interface Props {
  title: string
  colors: string[]
  points: number
  total: number
  gradientColors?: string[]
}

const PointSummary: React.FC<Props> = ({
  title,
  colors,
  children,
  points,
  total,
  gradientColors
}) => {
  const percentage = (points / total) * 100
  const series = [percentage]

  const options = {
    chart: {
      type: 'radialBar'
    },
    colors: colors,
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 500,
          size: '65%'
        },
        dataLabels: {
          name: {
            offsetY: -3,
            color: theme['dark-grey'],
            fontSize: '28px'
          },
          value: {
            offsetY: 5,
            color: theme['semi-dark-grey'],
            fontSize: '18px',
            show: true,
            formatter: function(val) {
              return `of ${total}`
            }
          }
        }
      }
    },
    fill: {
      type: gradientColors ? 'gradient' : 'none',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: gradientColors,
        stops: [0, 100]
      }
    },
    labels: [`${points}`]
  }

  return (
    <Grid container justify="space-evenly" direction="column" alignItems="center" className={styles.container}>
      <Grid direction="column">
        <H3 classnames={styles.centerText}>{title}</H3>

        {isBrowser && (
          <Suspense fallback={<div />}>
            <ReactApexChart options={options} series={series} type="radialBar" width={'300'} />
          </Suspense>
        )}
            <div className={styles.content}>{children}</div>
      </Grid>
    </Grid>
  )
}

export default PointSummary
