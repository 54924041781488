// extracted by mini-css-extract-plugin
export var bodyBase = "player-history-module--body-base--d9O+d player-history-module--site-font--EEBMj";
export var bodyLarge = "player-history-module--body-large--iKn+9 player-history-module--body-base--d9O+d player-history-module--site-font--EEBMj";
export var bodyLargeBold = "player-history-module--body-large-bold--RPZxY player-history-module--body-base--d9O+d player-history-module--site-font--EEBMj";
export var bodyRegular = "player-history-module--body-regular--gu-rV player-history-module--body-base--d9O+d player-history-module--site-font--EEBMj";
export var bodyRegularBold = "player-history-module--body-regular-bold--oG38G player-history-module--body-base--d9O+d player-history-module--site-font--EEBMj";
export var bodySmall = "player-history-module--body-small--yDIrI player-history-module--body-base--d9O+d player-history-module--site-font--EEBMj";
export var bodySmallBold = "player-history-module--body-small-bold--BjBTT player-history-module--body-base--d9O+d player-history-module--site-font--EEBMj";
export var borderTop = "player-history-module--border-top--VtywE";
export var breakWordContainer = "player-history-module--break-word-container--u+1K3";
export var buttonIconBase = "player-history-module--button-icon-base--N05Up";
export var clickLink = "player-history-module--click-link--cAplP";
export var dropdownBase = "player-history-module--dropdown-base--l7DKX";
export var dropdownSelectBase = "player-history-module--dropdown-select-base--fvQEv player-history-module--text-input--3xmN+";
export var flexCol = "player-history-module--flex-col--EjHn+";
export var formErrorMessage = "player-history-module--form-error-message--UDqqz";
export var h3 = "player-history-module--h3--CwCTR";
export var h4 = "player-history-module--h4--FjD9L";
export var hoverLink = "player-history-module--hover-link--i7XM7";
export var hoverRow = "player-history-module--hover-row--eLFMj";
export var membershipContainer = "player-history-module--membership-container--ykkoV player-history-module--flex-col--EjHn+ player-history-module--primary-border--RNBYs";
export var membershipHeader = "player-history-module--membership-header--2JSWX";
export var membershipHeading = "player-history-module--membership-heading--guBRP";
export var membershipLabel = "player-history-module--membership-label--jn8OY";
export var moreFiltersBorderClass = "player-history-module--more-filters-border-class--B9UgS";
export var pageBg = "player-history-module--page-bg--G4u5r";
export var pointer = "player-history-module--pointer--Ltm+s";
export var primaryBorder = "player-history-module--primary-border--RNBYs";
export var shadowBoxLight = "player-history-module--shadow-box-light--d+wSQ";
export var siteFont = "player-history-module--site-font--EEBMj";
export var slideDownAndFade = "player-history-module--slideDownAndFade--JzQ00";
export var slideLeftAndFade = "player-history-module--slideLeftAndFade--HFveD";
export var slideRightAndFade = "player-history-module--slideRightAndFade--qKlD9";
export var slideUpAndFade = "player-history-module--slideUpAndFade--8jgG7";
export var statusDecoration = "player-history-module--status-decoration--rebkz";
export var tableHeader = "player-history-module--table-header--taOwp";
export var textInput = "player-history-module--text-input--3xmN+";
export var textInverted = "player-history-module--text-inverted--JHZlP";
export var textMediumDark = "player-history-module--text-medium-dark--qe1SJ";
export var tooltipFont = "player-history-module--tooltipFont--riWKh";
export var unstyledButton = "player-history-module--unstyled-button--3FPWm";
export var wtnLogo = "player-history-module--wtnLogo--NYwCI";
export var wtnRating = "player-history-module--wtnRating--W7jsT";