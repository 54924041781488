import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { meshGatewayClient } from 'src/apollo/client'
import { CustomGrid } from 'src/components/custom-grid/custom-grid'
import Panel from 'src/components/panel/panel'
import { GET_PLAYER_TOURNAMENTS, GET_SEASONS, GET_TEAMS } from '../../queries/ITA/index'
import * as styles from './player-history.module.less'
import CalendarSvg from 'src/images/svg-components/usta-calendar-icon.svg'
import { BodyLargeBold, BodyRegular, ControlProps } from '@clubspark-react/clubspark-react-tools'
import { initQueryFilterTableHook } from 'src/hooks/use-query-filter-table'
import { Spacing } from 'src/hooks/spacing'
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes'
import { transformSortDirection } from '../table-controls/table-controls'
import moment from 'moment'
import { Td_personTournaments, Td_personTournaments_td_person_tournaments_items_matchUps_items } from 'src/graphql-types/ita/Td_personTournaments'
import { getConfidence, getOpponentTeam, getOpponents, getPlayerWtn, winLoss } from './player-history.utils'
import { Accordion, AccordionDetails, AccordionSummary } from '../accordion/accordion'
import { Body } from '../typography/typography'
import StatusLabel from '../status-label/status-label'
import { useQuery } from '@apollo/client'
import { GET_ITA_PERSON_BY_ID } from '../player-profile-ita/ita-player-profile-queries'
import { td_WorldTennisNumberTypeEnum } from 'src/graphql-types/ita/globalITATypes'
interface PlayerHistoryProps {
  id: string
}

export const PlayerHistory: React.FC<PlayerHistoryProps> = ({ id: uaid }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({})
  const [TeamList, setTeamList] = useState<string>()
  const [currentSeasonId, setCurrentSeasonId] = useState<string>()
  const [seasonList, setSeasonList] = useState<string>()
  const [currentPlayerId, setCurrentPlayerId] = useState<string>()

  const isExpanded = (id: string): boolean => expanded?.[id] === true

  const handleChange = (id: string) => (_: Event, isExpanded: boolean) => {
    setExpanded({ ...expanded, [id]: isExpanded })
  }

  const { data: groupsData, error: steamDataError, loading: teamDataLoading } = useQuery(GET_TEAMS, {
    client: meshGatewayClient,
  })

  const { data: seasonQueryData, error: seasonDataError, loading: seasonDataLoading } = useQuery(GET_SEASONS, {
    client: meshGatewayClient,
  })

  const { data: teamData } = useQuery(GET_ITA_PERSON_BY_ID, {
    client: meshGatewayClient,
    variables: {
      uniqueId: uaid,
      uniqueIdType: 'personId'
    }
  })

  const playerData = teamData?.personById
  const seasonData = teamData?.currentSeason

  const currentTeam = playerData?.groups?.find(group => group.seasonId === seasonData?.id)
  const teamId = currentTeam?.groupId

  const getQueryVarsFromContext = ctx => {
    const { between } = ctx.helper
    const { season } = ctx.filters

    const startYear = season.toString()
    const endYear = (parseInt(season) + 1).toString()

    return {
      tdPersonId: {
        identifier: uaid,
        type: 'ExternalID'
      },
      tournamentFilter: {
        start: between([new Date(`01-01-${startYear}`), new Date(`01-01-${endYear}`)])
      },
      tournamentSort: {
        direction: 'ASC',
        field: 'start'
      },
      matchUpsFilter: {
        personIds: [
          {
            identifier: uaid,
            type: 'ExternalID'
          }
        ]
      },
      matchUpsSort: ctx?.sorting?.length
        ? {
          field: ctx.sorting[0].property,
          direction: transformSortDirection(ctx.sorting[0].sortDirection)
        }
        : undefined
    }
  }

  const currentYear = new Date().getFullYear().toString()

  type TableItem = Td_personTournaments_td_person_tournaments_items_matchUps_items & { id: string }

  const useQFT = initQueryFilterTableHook<Td_personTournaments, TableItem>(GET_PLAYER_TOURNAMENTS)

  const filterSpacing: Spacing = {
    base: 6,
    margins: {
      xs: ['bottom', 'right']
    }
  }

  const protectFromUndefined = (name: string) => (name?.includes('undefined') ? '' : name)

  const startingYear = 2021
  const seasonFilterOptions: { label: string; value: string }[] = []

  for (let i = parseInt(currentYear); i >= startingYear; i--) {
    seasonFilterOptions.push({ label: `${i}/${i + 1}`, value: i.toString() })
  }

  function getWtnComponent(uaid: string, matchUp: Td_personTournaments_td_person_tournaments_items_matchUps_items, wtnType: td_WorldTennisNumberTypeEnum) {
    return getPlayerWtn(uaid, currentPlayerId, wtnType, matchUp.worldTennisNumbers)?.tennisNumber ? (
      <div className={styles.wtnRating}>
        {getPlayerWtn(uaid, currentPlayerId, wtnType, matchUp.worldTennisNumbers)?.tennisNumber}
        <img className={styles.wtnLogo} src={getConfidence(uaid, currentPlayerId, wtnType, matchUp.worldTennisNumbers)} />
      </div>
    ) : '-'
  }

  const {
    components: { FilterBar, AdminTable, props },
    query: { data, loading: loadingTournaments }
  } = useQFT(
    {
      season: {
        position: 2,
        type: 'select',
        initialValue: currentYear,
        props: {
          options: seasonFilterOptions,
          classNames: {
            trigger: styles.genderTrigger
          },
          placeholder: seasonFilterOptions[0].label
        },
        spacing: filterSpacing
      }
    },
    {
      columns: [
        {
          key: 'round',
          title: t('round'),
          sort: false,
          getValue: m => m.roundName ? protectFromUndefined(m.roundName) : '-'
        },
        {
          key: 'team',
          title: t('team'),
          sort: false,
          getValue: m => getOpponentTeam(m.extensions, groupsData, seasonQueryData, playerData, m.start, m.end) || '-'
        },
        {
          key: 'position',
          title: t('position of play'),
          sort: false,
          getValue: m => m.roundPosition ? `#${m.roundPosition}` : '-'
        },
        {
          key: 'opponents',
          title: t('opponents'),
          sort: false,
          getValue: m => (getOpponents(uaid, currentPlayerId, m.sides) ? getOpponents(uaid, currentPlayerId, m.sides) : '-')
        },
        {
          key: 'winningSide',
          title: t('w/l'),
          sort: false,
          getValue: m => winLoss(uaid, currentPlayerId, m.sides, m.winningSide) ?? '-'
        },
        {
          key: 'score',
          title: t('results'),
          sort: false,
          getValue: m => (m.score?.scoreString) ?? '-'
        },
        {
          key: 'wtnSingles',
          title: t('wtn singles'),
          sort: false,
          getValue: m => getWtnComponent(uaid, m, td_WorldTennisNumberTypeEnum.SINGLE)
        },
        {
          key: 'wtnDoubles',
          title: t('wtn doubles'),
          sort: false,
          getValue: m => getWtnComponent(uaid, m, td_WorldTennisNumberTypeEnum.DOUBLE)
        },
        {
          key: 'status',
          title: t('wtn status'),
          sort: false,
          getValue: m => m.wtnId ? (
            <StatusLabel spacing={{ margins: { sm: 'right' } }} variety={'success'}>
              {t('published')}
            </StatusLabel>
          ) : (
            '-'
          )
        }
      ]
    },
    {
      columnSelectorId: 'playerHistory',
      urlParams: {},
      mapFiltersToQueryOptions: ({ filters, helper }) => {
        return {
          client: meshGatewayClient,
          getTotalItems: () => 0,
          transformVariables: (vars: {
            limit?: number
            offset?: number
            sorts?: PropertySortInput[]
          }) => {
            const { limit, offset, sorts } = vars
            return getQueryVarsFromContext({
              filters,
              paging: { limit, offset },
              sorting: sorts,
              helper
            })
          },
          fetchPolicy: 'no-cache'
        }
      },
      mapDataToTable: () => [],
      depsTableConfig: [TeamList, currentSeasonId, seasonList, currentPlayerId]
    }
  )

  useEffect(() => {
    if (groupsData?.findGroup?.results) setTeamList(groupsData?.findGroup?.results)
    if (teamData?.currentSeason?.id) setCurrentSeasonId(teamData?.currentSeason?.id)
    if (seasonQueryData?.listSeasons) setSeasonList(seasonQueryData?.listSeasons)
    if (data?.td_person?.id) setCurrentPlayerId(data?.td_person?.id)
  }, [groupsData, teamData, seasonQueryData, data])


  const tournamentArray = data?.td_person?.tournaments?.items

  function getTournamentName(tournament) {
    const tournamentName = tournament?.name || ''
    const level = tournament?.level
    const levelLabel = level ? `${t('level')} ${level}` : ''

    return level ? `${levelLabel} - ${tournamentName}` : tournamentName
  }
  function getTournamentDescription(tournament) {
    const tournamentDate = tournament?.start ? t('dateFormat.default', {
      date: moment(tournament?.start).local()
    }) : ''

    const drawType = tournament?.matchUps?.items[0]?.drawType || ''
    const spacing = tournamentDate && drawType ? " | " : ""

    return `${tournamentDate} ${spacing} ${drawType}`
  }

  const controls: ControlProps = {
    onControlChange: () => { },
    paginaitonDisabled: true
  }

  return (
    <Panel title={t('tournaments')}>
      <CustomGrid container spacing={{ margins: { sm: 'top' } }}>
        <FilterBar {...props.filterBar} />
      </CustomGrid>
      {!loadingTournaments &&
        tournamentArray?.map((tournament, index) => (
          <CustomGrid key={`${tournament.id}-${index}`} container spacing={{ margins: { lg: 'top' } }}>
            <Accordion expanded={isExpanded(tournament.id)} handleChange={handleChange(tournament.id)}>
              <AccordionSummary>
                <Grid container className={styles.tableHeader}>
                  <Grid>
                    <Body size="lg" bold spacing={{ margins: { xxs: 'left' } }}>
                      {getTournamentName(tournament)}
                    </Body>
                    <Body size="md" spacing={{ margins: { xxs: ['top', 'left'] } }}>
                      {getTournamentDescription(tournament)}
                    </Body>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <AdminTable
                  {...props.adminTable}
                  controls={controls}
                  hideTopPaginationInfo={true}
                  data={tournament?.matchUps?.items}
                  spacing={{ margins: { xs: 'top' } }}
                  fixedWidth
                />
              </AccordionDetails>
            </Accordion>
          </CustomGrid>
        ))}
      {!tournamentArray && !loadingTournaments && (
        <Grid container direction="column" justify="center" alignItems="center">
          <CalendarSvg className={styles.icon} />
          <BodyLargeBold>{t('no tournament data')}</BodyLargeBold>
          <BodyRegular>{t('no tournament data for player')}</BodyRegular>
        </Grid>
      )}
    </Panel>
  )
}
