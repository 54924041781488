import graphql from 'graphql-tag'

export const GET_ITA_PERSON_BY_ID = graphql`
query PersonData($uniqueId: String!, $uniqueIdType: UniqueIdType!) {
  personById(uniqueId: $uniqueId, uniqueIdType: $uniqueIdType) {
    class
    age
    standardFamilyName
    standardGivenName
    worldTennisNumbers {
      confidence
      tennisNumber
      type
    }
    birthDate
    category
    externalId
    groups {
      groupId
      groupName
      groupType
      role
      status
      seasonId
      seasonName
    }
    status
    tennisId
    userId
    sex
    personId
    nationalityCode
  }

  currentSeason: getCurrentSeason {
    id
  }
}
`