function convertToTitleCase(input: string): string {
  if (!input) {
    return ''
  }
  // Split the input string into words based on underscores
  const words = input.split('_')

  // Capitalize the first letter of each word and join them back
  const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  return titleCaseWords.join(' ')
}

export default convertToTitleCase