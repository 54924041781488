import React from 'react'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails, {
  AccordionDetailsProps as MuiAccordionDetailsProps
} from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary, {
  AccordionSummaryProps as MuiAccordionSummaryProps
} from '@material-ui/core/AccordionSummary'
import Icon from 'src/components/icon/icon'
import * as styles from './accordion.module.less'

type AccordionSummaryProps = MuiAccordionSummaryProps

export const AccordionSummary: React.FC<AccordionSummaryProps> = ({ children, ...props }) => {
  return (
    <MuiAccordionSummary
      expandIcon={<Icon name="sm-down" />}
      {...props}
      classes={{ root: styles.summaryRoot, content: styles.content }}
    >
      {children}
    </MuiAccordionSummary>
  )
}

type AccordionDetailsProps = MuiAccordionDetailsProps

export const AccordionDetails: React.FC<AccordionDetailsProps> = ({ children, ...props }) => {
  return <MuiAccordionDetails {...props}>{children}</MuiAccordionDetails>
}

export const Accordion = ({ expanded, handleChange, children }) => {
  return (
    <MuiAccordion 
      data-testid='accordion-root' 
      expanded={expanded} 
      onChange={handleChange} 
      classes={{ root: styles.root }}
    >
      {children}
    </MuiAccordion>
  )
}
