import { Icon } from '@clubspark-react/clubspark-react-tools'
import * as styles from './player-ranking.module.less'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/button/button'
import { CustomGrid } from 'src/components/custom-grid/custom-grid'
import RankingPlayHistoryItemAccordian from './player-ranking-play-history-item'
import { useQuery } from '@apollo/client'
import { TD_GET_RANK_LIST_ELIGIBLE_PARTICIPANTS } from '../queries'
import { meshGatewayClient } from 'src/apollo/client'
import { parseRankListInput } from '../utils/parseRankListInput'
import { TD_GetRankListParticipants, TD_GetRankListParticipants_td_rankListEligibleParticipants } from 'src/graphql-types/TD_GetRankListParticipants'
import { TD_GetMatchUps, TD_GetMatchUps_td_matchUps_items } from 'src/graphql-types/TD_GetMatchUps'
import { TD_GET_MATCH_UPS } from '../queries/getRankingMatchUps'
import { TD_GetRankList_td_rankList_rankingItems_items, TD_GetRankList_td_rankList_rankingItems_items_contributingPlayHistory } from 'src/graphql-types/TD_GetRankList'
import { sortOnTournamentDates } from '../utils/sortOnTournamentDates'

const mapResult = (originalResult) => {
  const {
    matchUpId,
    matchUpStatus,
    matchUpType,
    matchWon,
    opponentId,
    opponentPartnerId,
    partnerId,
    points,
    bonusPointsWinValue,
    round,
    stage,
    structureName,
    scoreString,
  } = originalResult || {}

  return {
    matchUpId,
    matchUpStatus,
    matchUpType,
    matchWon,
    opponentId,
    opponentPartnerId,
    partnerId,
    points,
    bonusPointsWinValue,
    round,
    stage,
    structureName,
    scoreString,
  }
}

const mapEvent = (event, isFromContributingPlayHistory, uniqueIdsSet) => {
  const {
    originalRankingParticipants,
    points,
    notFromList,
    division: eventDivision,
  } = event

  const {
    tournamentId,
    eventId,
    drawId,
    collectionId,
    tournamentStart,
    tournamentEnd,
    division,
    singlesPoints,
    doublesPoints,
    bonusPoints,
    participationPoints,
    results: contextualResults,
  } = isFromContributingPlayHistory
    ? originalRankingParticipants?.[0] || {}
    : event

  const originalResults = originalRankingParticipants?.[0]?.results || []

  const originalResultMap = originalResults.reduce((acc, result) => {
    acc[result.matchUpId] = result
    return acc
  }, {})

  const results = contextualResults?.map((listResult) => {
    // if the result is not from the rank list, the original result is the same as the list result
    const originalResult = originalResultMap[listResult.matchUpId] || listResult
    return mapResult(originalResult)
  })

  const uniqueId = `${tournamentId}-${eventId}-${drawId}-${collectionId}`
  uniqueIdsSet.add(uniqueId)

  return {
    tournamentStart: tournamentStart && new Date(tournamentStart),
    tournamentEnd: tournamentEnd && new Date(tournamentEnd),
    tournamentId,
    eventId,
    drawId,
    collectionId,
    level: isFromContributingPlayHistory ? originalRankingParticipants?.[0]?.level : event.level,
    eventName: isFromContributingPlayHistory ? originalRankingParticipants?.[0]?.eventName : event.eventName,
    eventType: isFromContributingPlayHistory ? originalRankingParticipants?.[0]?.eventType : event.eventType,
    division: division || eventDivision,
    ageCategory: division?.ageCategory || eventDivision?.ageCategory,
    gender: division?.gender || eventDivision?.gender,
    ratingCategory: division?.ratingCategory || eventDivision?.ratingCategory,
    wheelchairRating: division?.wheelchairRating || eventDivision?.wheelchairRating,
    familyType: division?.familyType || eventDivision?.familyType,
    points,
    originalPoints: {
      singlesPoints,
      doublesPoints,
      bonusPoints,
      participationPoints,
      totalPoints: singlesPoints + doublesPoints + bonusPoints + participationPoints,
    },
    results,
    notFromList,
  }
}

const getFormattedPlayerEvents = (
  playerEvents: TD_GetRankList_td_rankList_rankingItems_items_contributingPlayHistory[], 
  eligibleParticipants: TD_GetRankListParticipants_td_rankListEligibleParticipants[]
) => {
  const uniqueEventIds = new Set()

  const mappedPlayerEvents = playerEvents?.map((event) => {
    return mapEvent(event, true, uniqueEventIds)
  })

  const mappedEligibleParticipants = eligibleParticipants?.map((participant) => {
    return uniqueEventIds.has(`${participant.tournamentId}-${participant.eventId}-${participant.drawId}-${participant.collectionId}`)
      ? null
      : mapEvent({ ...participant, notFromList: true }, false, uniqueEventIds)
  })

  return [
    ...(mappedPlayerEvents?.sort(sortOnTournamentDates) || []), 
    ...(mappedEligibleParticipants?.sort(sortOnTournamentDates) || []).filter(Boolean)
  ]
}

interface PlayerRankingPlayHistoryProps {
  personId: string
  rankList?: TD_GetRankList_td_rankList_rankingItems_items | null
  loading: boolean
}

const PlayerRankingPlayHistory: React.FC<PlayerRankingPlayHistoryProps> = ({ 
  personId, 
  rankList, 
  loading 
}) => {
  const { t } = useTranslation()
  const [isAllExpanded, setIsAllExpanded] = useState(false)
  const [playerEvents, setPlayerEvents] = useState<any>([])
  const [matchUpIds, setMatchUpIds] = useState<string[]>([])

  const { data: rankListParticipants } = useQuery<TD_GetRankListParticipants>(
    TD_GET_RANK_LIST_ELIGIBLE_PARTICIPANTS,
    {
      client: meshGatewayClient,
      variables: {
        filter: {
          personIds: [personId],
          // typescript isn't smart enough to know that the skip condition below means that this
          // rankList will never be null when we get here
          rankListInput: parseRankListInput(rankList as any)
        },
        sort: {
          field: 'tournamentEnd',
          direction: 'DESC'
        },
      },
      skip: !rankList
    }
  )

  const { data: rankListMatchUps } = useQuery<TD_GetMatchUps>(
    TD_GET_MATCH_UPS,
    {
      client: meshGatewayClient,
      variables: {
        filter: {
          providerMatchIds: matchUpIds
        }
      },
      skip: !matchUpIds?.length
    }
  )

  const matchUps = useMemo(() => {
    return rankListMatchUps?.td_matchUps?.items?.reduce((map, matchUp) => {
      map[matchUp.providerMatchId] = matchUp
      return map
    }, {} as { [key: string]: TD_GetMatchUps_td_matchUps_items })
  }, [rankListMatchUps?.td_matchUps?.items])

  useEffect(() => {
    const playerEvents = getFormattedPlayerEvents(
      rankList?.contributingPlayHistory as TD_GetRankList_td_rankList_rankingItems_items_contributingPlayHistory[], 
      rankListParticipants?.td_rankListEligibleParticipants as TD_GetRankListParticipants_td_rankListEligibleParticipants[]
    )
    setPlayerEvents(playerEvents)
    setMatchUpIds(playerEvents?.flatMap(event => event?.results?.map(result => result.matchUpId) || []))
  }, [rankList?.contributingPlayHistory, rankListParticipants?.td_rankListEligibleParticipants])

  const rankingPlayHistoryEvents = useMemo(() => {
    return playerEvents?.map((event) => {
      // this is a bit scrappy until we persist additional data on the ranking participants
      // as we're fetching additional match up data anyway, we can get the tournament name from there
      const tournamentName = event.tournamentName || matchUps?.[event.results?.[0]?.matchUpId]?.tournament?.name
      return {
        ...event,
        tournamentName,
        results: event?.results?.map(result => {
          return {
            ...result,
            fullMatchUp: matchUps?.[result.matchUpId]
          }
        })
      }
    })
  }, [matchUps, playerEvents])

  const handleBatchExpand = useCallback(() => {
    setIsAllExpanded(!isAllExpanded)
  }, [isAllExpanded])

  return (
    <CustomGrid
      container
      justifyContent="flex-end"
      hide={loading}
      spacing={{ margins: { lg: 'top', xxs: 'bottom' } }}
    >
      <Button linkStyle onClick={() => handleBatchExpand()}>
        {isAllExpanded ? t('collapse all') : t('expand all')}
        <Icon name={isAllExpanded ? 'sm-up' : 'sm-down'} className={styles.arrowIcon} />
      </Button>
      {rankingPlayHistoryEvents?.map((event, index) => (
        <RankingPlayHistoryItemAccordian
          key={`${event.tournamentId}-${event.eventId}-${event.drawId}-${event.collectionId}`}
          event={event}
          expanded={isAllExpanded}
          onToggleExpand={(newExpanded) => {
            const newPlayerEvents = [...playerEvents]
            newPlayerEvents[index].isExpanded = newExpanded
            setPlayerEvents(newPlayerEvents)
          }}
        />
      ))}
    </CustomGrid>
  )
}

export default PlayerRankingPlayHistory