import React, { useState, useMemo, useEffect } from 'react'
import download from 'downloadjs'
import { Grid, LinearProgress, LinearProgressProps } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table'
import Panel from 'src/components/panel/panel'
import { Body, H2 } from 'src/components/typography/typography'
import Icon, { IconName } from '../icon/icon'
import * as styles from './suspension-player.module.less'
import { SpacingProps } from '@clubspark-react/clubspark-react-tools/dist/hooks/spacing'
import { useSpacing } from 'src/hooks/spacing'
import cx from 'classnames'
import { CustomGrid } from 'src/components/custom-grid/custom-grid'
import Button from 'src/components/button/button'
import { navigate } from 'gatsby'
import CustomDialog from '../custom-dialog/custom-dialog'
import InputLabel from '../input-label/input-label'
import { TextField, DateField } from '../formik-fields/formik-fields'
import { Form, Formik } from 'formik'
import {
  GET_PLAYER_SUSPENSION_STATUS,
  LIST_PLAYER_VIOLATIONS,
  LIST_PLAYER_ACTIONS,
  CREATE_SUSPENSION_EMAIL,
  UPDATE_SUSPENSION_EMAIL,
  LIST_SUSPENSIONS_FOR_PLAYER
} from '../players/players-queries'
import { meshGatewayClient } from 'src/apollo/client'
import { useQuery, useMutation } from '@apollo/client'
import Spinner from '../spinner/spinner'
import * as Yup from 'yup'
import { useControlledQuery } from 'src/components/table-controls/table-controls'
import Dropdown from '../dropdown/dropdown'
import { getEnvConfig } from 'src/config/config'
import { getToken } from 'src/utils/auth'
import { useOrgId } from 'src/apollo/local-state'
import { ListPlayerActions_listPlayerActions_actions as ListPlayerActions } from 'src/graphql-types/ListPlayerActions'
import { TFunction } from 'i18next'
import moment from 'moment'
import { resolveViolationDate } from 'src/utils/helper/suspension-utils'

enum ActionType {
  SuspensionCodeAdded = 'suspension code added',
  SuspensionCodeDeleted = 'suspension code deleted',
  SuspensionCodeEdited = 'suspension code edited',
  ViolationAdded = 'violation added',
  ViolationDeleted = 'violation deleted',
  ViolationEdited = 'violation edited',
  SuspensionAdded = 'suspension added',
  SuspensionDeleted = 'suspension deleted',
  SuspensionEdited = 'suspension edited',
  SuspensionEmailSent = 'suspension email sent',
  SuspensionEmailUpdated = 'suspension email updated',
  SuspensionEmailDeleted = 'suspension email deleted',
  WarningEmailSent = 'warning email sent',
  WarningEmailUpdated = 'warning email updated',
  WarningEmailDeleted = 'warning email deleted',
  PlayerMerge = 'player merge'
}

interface StatusOverviewProps {
  status: 'good' | 'warning' | 'suspended' | 'awaiting suspension'
  id: string
  suspension: any
}

interface StatusLevel {
  status: string
  icon: IconName
  title: string
  paragraphs: string[]
  buttons?: any
}

interface StatusDialogProps {
  label: string
  type: 'Warning' | 'Suspension'
  uaid: string
  initValues?: {
    id: string
    timestamp: Date
    comment: string | null
  }
  refetch?: any
}

export const getTotalItems = (d: any) => d?.listPlayerActions?.totalActions

const StatusDialog: React.FC<StatusDialogProps> = ({ label, type, uaid, initValues, refetch }) => {
  const { t } = useTranslation()
  const [dialog, setDialog] = useState(false)

  const [createEmail, { loading }] = useMutation(CREATE_SUSPENSION_EMAIL, {
    client: meshGatewayClient
  })
  const [updateEmail, { loading: updatingEmail }] = useMutation(UPDATE_SUSPENSION_EMAIL, {
    client: meshGatewayClient
  })

  const initialValues = initValues ?? { timestamp: new Date(), comment: '' }

  const onSubmit = async values => {
    try {
      if (initValues) {
        await updateEmail({
          variables: {
            id: initValues.id,
            input: {
              comment: values.comment,
              uaid,
              type,
              sentDate: values.timestamp
            }
          }
        })
        refetch?.()
      } else if (values) {
        await createEmail({
          variables: {
            input: {
              comment: values.comment,
              uaid: uaid,
              type: type,
              sentDate: values.timestamp
            }
          }
        })
      }
      refetch?.()
      setDialog(false)
    } catch {}
  }

  const validationSchema = Yup.object().shape({
    comment: Yup.string().max(500, t('500 characters max'))
  })

  return (
    <>
      {/* If it has initValues - it means the component is being used for editing */}
      {initValues && (
        <Button
          type="button"
          linkStyle
          onClick={() => setDialog(true)}
          spacing={{ margins: { xs: 'right' } }}
        >
          {t('edit')}
        </Button>
      )}
      {!initValues && (
        <Button
          level="tertiary"
          type="button"
          onClick={() => setDialog(true)}
          spacing={{ margins: { xs: 'right' } }}
        >
          {label}
        </Button>
      )}
      <CustomDialog
        title={label}
        open={dialog}
        content={
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, values, touched }) => (
              <Form>
                <InputLabel className={styles.bold} spacing={{ margins: { lg: 'vertical' } }}>
                  {t('date')}
                </InputLabel>
                <DateField
                  name={'timestamp'}
                  datePickerProps={{
                    spacing: { margins: { xxs: 'top', lg: 'bottom', md: 'right' } }
                  }}
                />
                <InputLabel spacing={{ margins: { lg: 'top' } }}>{t('comments')}</InputLabel>
                <TextField
                  name={'comment'}
                  multiline
                  rows={8}
                  fullWidth
                  spacing={{ margins: { xxs: 'top' } }}
                />
                <div className={styles.buttons}>
                  <Button type="button" level="tertiary" onClick={() => setDialog(false)}>
                    {t('close')}
                  </Button>
                  <Button
                    type="submit"
                    loading={loading || updatingEmail}
                    spacing={{ margins: { sm: 'left' } }}
                  >
                    {t('save')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        }
        onClose={() => setDialog(false)}
      />
    </>
  )
}

const StatusOverview: React.FC<StatusOverviewProps> = ({ status, id, suspension }) => {
  const { t } = useTranslation()

  const SharedButtons = () => {
    return (
      <>
        <StatusDialog label={t('warning email')} type="Warning" uaid={id} />
        <StatusDialog label={t('suspension letter')} type="Suspension" uaid={id} />
      </>
    )
  }

  const statusLevels: StatusLevel[] = [
    {
      status: 'None',
      icon: 'md-tick-circle',
      title: t('all good'),
      paragraphs: [t('no risk suspension')],
      buttons: [
        {
          text: t('suspend player'),
          onClick: () => navigate(`/players/${id}/suspend-player`)
        }
      ]
    },
    {
      status: 'AtRisk',
      icon: 'md-alert-triangle',
      title: t('warning'),
      paragraphs: [t('player risk suspension')],
      buttons: [
        {
          text: t('suspend player'),
          onClick: () => navigate(`/players/${id}/suspend-player`)
        }
      ]
    },
    {
      status: 'AwaitingSuspension',
      icon: 'md-penalty',
      title: t('awaiting suspension'),
      paragraphs: [t('suspension due')],
      buttons: [
        {
          text: t('suspend player'),
          onClick: () => navigate(`/players/${id}/suspend-player`)
        }
      ]
    },
    {
      status: 'Suspended',
      icon: 'md-penalty',
      title: t('suspended'),
      paragraphs: [
        t('player suspended for', {
          startDate: moment.utc(suspension?.startDate).format('ll'),
          endDate: moment.utc(suspension?.endDate).format('ll')
        })
      ],
      buttons: [
        {
          text: t('edit suspension'),
          onClick: () => navigate(`/players/${id}/edit-suspension`)
        }
      ]
    }
  ]

  const currentStatus = statusLevels.find(s => s.status === status) as StatusLevel

  return (
    <Panel>
      <Grid container className={styles.suspensionContainer}>
        <CustomGrid container spacing={{ margins: { md: 'bottom' } }} alignItems="center">
          <Icon name={currentStatus?.icon} />
          <Body size="lg" bold spacing={{ margins: { xs: 'left', xxs: 'top' } }}>
            {currentStatus?.title}
          </Body>
        </CustomGrid>
        {currentStatus?.paragraphs.map(p => (
          <Body size="md" key={p}>
            {p}
          </Body>
        ))}
        <CustomGrid
          container
          alignItems="center"
          spacing={{ margins: { md: 'top' } }}
          className={styles.buttonContainer}
        >
          <SharedButtons />
          {currentStatus?.buttons?.map(({ text, onClick, props }) => (
            <Button key={text} {...props} onClick={onClick}>
              {text}
            </Button>
          ))}
        </CustomGrid>
      </Grid>
    </Panel>
  )
}

interface SuspensionProgressProps extends LinearProgressProps, SpacingProps {
  value: number
}

const SuspensionProgress: React.FC<SuspensionProgressProps> = ({ spacing, value, ...props }) => {
  const spacingClass = useSpacing(spacing)

  const getBarColorStyle = value => {
    if (value < 4) {
      return styles.barColorGreen
    } else if (value < 10) {
      return styles.barColorWarning
    } else {
      return styles.barColorSuspended
    }
  }

  return (
    <LinearProgress
      variant="determinate"
      className={cx(styles.linearProgress, spacingClass)}
      classes={{ barColorPrimary: getBarColorStyle(value) }}
      {...props}
      value={value > 10 ? 100 : value * 10}
    />
  )
}

const PointSummary = ({ value }) => {
  const { t } = useTranslation()

  return (
    <Panel>
        <CustomGrid spacing={{ margins: { xs: ['left', 'right', 'top'], md: 'bottom' } }}>
          <Body size="lg" bold spacing={{ margins: { md: 'bottom' } }}>
            {t('points summary')}
          </Body>
          <p className={styles.points}>
            {value}/10 {t('points')}
          </p>
          <SuspensionProgress value={value} />
          <Body size="md" light spacing={{ margins: { xs: 'top' } }}>
            {t('suspension warning message')}
          </Body>
        </CustomGrid>
      </Panel>
  )
}

const SuspensionPlayer = ({ id }) => {
  const { t } = useTranslation()

  const initialOptions = [
    { label: t('active'), value: 'active' },
    { label: t('expired'), value: 'expired' }
  ]

  const [options, setOptions] = useState(initialOptions)
  const [filter, setFilter] = useState<string | undefined>(undefined)

  const { loading: loadingStatus, error: statusError, data: statusData } = useQuery(
    GET_PLAYER_SUSPENSION_STATUS,
    {
      client: meshGatewayClient,
      fetchPolicy: 'no-cache',
      variables: { uaid: id }
    }
  )

  const {
    loading: loadingViolations,
    error: violationsError,
    data: violations,
    refetch: refetchViolations
  } = useQuery(LIST_PLAYER_VIOLATIONS, {
    client: meshGatewayClient,
    fetchPolicy: 'no-cache',
    variables: { uaid: id, expired: false }
  })

  const { data: suspensions, loading: loadingSuspensions, error: suspensionError } = useQuery(
    LIST_SUSPENSIONS_FOR_PLAYER,
    {
      client: meshGatewayClient,
      fetchPolicy: 'no-cache',
      variables: { uaid: id }
    }
  )

  const listOfSuspensionIds = suspensions?.listSuspensionsForPlayer?.map(s => s.id)

  const transformVariables = optionsWithControls => {
    const { limit = 10, offset = 0 } = optionsWithControls ?? {}

    return {
      uaid: id,
      pageArgs: {
        limit,
        skip: offset
      }
    }
  }

  const { data: actions, loading: loadingActions, refetch, controlProps } = useControlledQuery(
    LIST_PLAYER_ACTIONS,
    {
      client: meshGatewayClient,
      getTotalItems,
      transformVariables,
      fetchPolicy: 'no-cache'
    }
  )

  const actionsData = useMemo(
    () =>
      actions?.listPlayerActions?.actions?.map(action => ({
        ...action,
        id: `${action.object.id}-${action.createdAt}`
      })),

    [actions]
  )

  const cols = [
    { key: 'code', title: t('code'), getValue: m => m?.suspensionCode?.code },
    { key: 'points', title: t('points'), getValue: m => m?.suspensionCode?.points },
    {
      key: 'dateReported',
      title: t('violation date'),
      getValue: m => resolveViolationDate(m, 'violationDate')
    },
    {
      key: 'expDate',
      title: t('exp date'),
      getValue: m => resolveViolationDate(m, 'expiryDate')
    },
    {
      key: 'reporter',
      title: t('reporter'),
      getValue: m =>
        m?.reporterFirstName ? m?.reporterFirstName + ' ' + m?.reporterLastName : t('n/a')
    },
    { key: 'tournament', title: t('tournament'), getValue: m => m?.tournamentName || t('n/a') },
    {
      key: 'edit',
      title: ' ',
      getValue: d => (
        <Button linkStyle onClick={() => navigate(`suspensions/violations/${d.id}`)}>
          {t('edit')}
        </Button>
      )
    }
  ]

  function getTakenByValue(user: ListPlayerActions['user'], t: TFunction) {
    if (!user) return t('system')
    const { firstName = '', lastName = '' } = user
    return `${firstName} ${lastName}`
  }

  const actionsCols = [
    { key: 'type', title: t('type'), getValue: m => t(ActionType[m.type]), sort: false },
    {
      key: 'date',
      title: t('date'),
      getValue: m => moment.utc(m.createdAt).format('ll'),
      sort: false
    },
    {
      key: 'takenBy',
      title: t('taken by'),
      getValue: m => getTakenByValue(m.user, t),
      sort: false
    },
    {
      key: 'comment',
      title: t('comment'),
      getValue: m => m.object?.comment || '',
      sort: false
    },
    {
      key: 'edit',
      title: ' ',
      getValue: m => {
        const { __typename, id: actionId, comment } = m.object

        if (m.type === 'PlayerMerge' || __typename === 'Merge') {
          return <div style={{ height: 40 }} />
        }

        if (m.type === 'SuspensionEdited' && !listOfSuspensionIds?.includes(actionId)) {
          return null
        }

        if (__typename === 'SuspensionEmail') {
          return (
            <StatusDialog
              label={t('warning email')}
              type="Warning"
              uaid={id}
              initValues={{ id: actionId, timestamp: m.createdAt, comment }}
              refetch={refetch}
            />
          )
        }

        return (
          m.objectDeleted === false && (
            <Button linkStyle onClick={() => handleEditActionClick(m)}>
              {t('edit')}
            </Button>
          )
        )
      },
      sort: false
    }
  ]

  const handleEditActionClick = m => {
    const { __typename, id: actionId } = m.object
    switch (__typename) {
      case 'Suspension':
        navigate(`/players/${id}/suspensions/${actionId}`)
        break
      case 'Violation':
        navigate(`/players/${id}/suspensions/violations/${actionId}`)
        break
      case 'SuspensionEmail':
        navigate(`/players/${id}/suspensions/violations/${actionId}`)
        break

      default:
        break
    }
  }

  const handleViolationClick = m => {
    navigate(`suspensions/violations/${m.id}`)
  }

  useEffect(() => {
    if (suspensions?.listSuspensionsForPlayer) {
      const list = suspensions.listSuspensionsForPlayer.map(s => {
        const startDate = moment.utc(s.startDate).format('DD/MM/YYYY')
        const endDate = moment.utc(s.endDate).format('DD/MM/YYYY')
        return { value: s.id, label: `${t('suspension')} ${startDate}-${endDate}` }
      })
      const newFilterList = initialOptions.concat(list)
      setOptions(newFilterList)
    }
  }, [suspensions])

  useEffect(() => {
    const variables = {
      uaid: id
    }

    if (filter) {
      if (filter === 'active') {
        Object.assign(variables, { expired: false, suspensionId: null })
      } else if (filter === 'expired') {
        Object.assign(variables, { expired: true, suspensionId: null })
      } else {
        Object.assign(variables, { expired: null, suspensionId: filter })
      }
    }

    refetchViolations(variables)
  }, [filter])

  const hasViolations = violations?.listViolationsForPlayer?.length > 0
  const orgId = useOrgId()

  const handleDownload = async () => {
    const token = getToken()
    const res = await fetch(`${getEnvConfig().SUSPENSION_SERVICE_URL}/export/violations/${id}`, {
      headers: {
        authorization: token!,
        'x-clubspark-provider-id': orgId
      }
    })
    download(await res.blob(), `Violation_Record_${id}`, 'text/plain')
  }

  return (
    <>
      <Grid container spacing={4}>
        {loadingStatus ? (
          <Grid container className={styles.spacing}>
            <Spinner />
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <PointSummary value={statusData?.getPlayerSuspensionStatus?.points} />
            </Grid>
            <Grid item xs={6}>
              <StatusOverview
                status={statusData?.getPlayerSuspensionStatus?.status}
                suspension={statusData?.getPlayerSuspensionStatus?.suspension}
                id={id}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Panel>
        <Grid container justify="space-between" alignItems="flex-end">
          <H2 spacing={{ margins: { auto: ['top', 'bottom'] } }}>{t('suspension points')}</H2>

          <div className={styles.actionContainer}>
            <Button
              level="tertiary"
              spacing={{ margins: { sm: 'right' } }}
              disabled={!hasViolations}
              onClick={handleDownload}
            >
              {t('download')}
            </Button>
            <Button onClick={() => navigate('suspensions/add-violation')}>
              <Icon name="sm-add" className={styles.addIcon} /> {t('add points')}
            </Button>
          </div>
        </Grid>
        <Dropdown
          selected={filter}
          options={options}
          onSelect={l => setFilter(l.value)}
          spacing={{ margins: { sm: 'vertical' } }}
        />
        {loadingViolations ||
          (violations && (
            <AdminTable
              data={violations?.listViolationsForPlayer}
              columns={cols}
              onRowClick={handleViolationClick}
              spacing={{ margins: { sm: 'vertical' } }}
              loading={loadingViolations}
            />
          ))}
      </Panel>
      <Panel>
        <H2 spacing={{ margins: { auto: ['top', 'bottom'] } }}>{t('actions')}</H2>
        {(actions || loadingActions) && (
          <AdminTable
            data={actionsData}
            loading={loadingActions}
            columns={actionsCols}
            spacing={{ margins: { sm: 'vertical' } }}
            controls={controlProps}
          />
        )}
      </Panel>
    </>
  )
}

export default SuspensionPlayer
