import { Grid, Tooltip } from '@material-ui/core'
import * as styles from './player-ranking.module.less'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionDetails, AccordionSummary } from 'src/components/accordion/accordion'
import { CustomGrid } from 'src/components/custom-grid/custom-grid'
import { Body } from 'src/components/typography/typography'
import Icon from 'src/components/icon/icon'
import AdminTable from 'src/components/admin-table/admin-table'
import { DataCols } from '@clubspark-react/clubspark-react-tools'
import { EventType } from 'src/graphql-types/globalTournamentTypes'
import useDateTime from 'src/utils/helper/useDateTime'
import convertToTitleCase from 'src/utils/helper/convertToTitleCase'
import getDivisionDefinition from '../utils/getDivisionName'
import { protectFromUndefined } from 'src/utils/helper/protectFromUndefined'
import { combineStringsCommaDelimited } from 'src/utils/helper/combineStringsCommaDelimited'

interface RankingPlayHistoryItemAccordianProps {
  event: any // awaiting API updates to type this based off TD RankingParticiapnts
  expanded: boolean
  onToggleExpand: (expanded: boolean) => void
}

const RankingPlayHistoryItemAccordian: React.FC<RankingPlayHistoryItemAccordianProps> = ({ 
  event, 
  expanded, 
  onToggleExpand 
}) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(expanded)
  const { formatDate } = useDateTime(
    undefined, 
    { 
      format: { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric', 
      }
    }
  )

  const isDoublesList = event?.eventType === EventType.DOUBLES
  const showBonusPoints = event?.ageCategory?.maximumAge
  
  const title = useMemo(() => {
    return `${t('level')} ${event?.level} - ${event?.tournamentName || event?.tournamentId}`
  },[event?.level, event?.tournamentId, event?.tournamentName, t])
  
  const description = useMemo(() => {
    const eventName = event?.division ? getDivisionDefinition(event.division) : event?.eventName
    const start = event?.tournamentStart ? formatDate(event.tournamentStart) : ''
    const end = event?.tournamentEnd ? ` - ${formatDate(event.tournamentEnd)}` : ''

  
    return `${eventName} | ${start}${end}`
  }, [event?.division, event?.eventName, event.tournamentEnd, event.tournamentStart, formatDate])

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  const handleToggleExpand = useCallback(() => {
    const newExpanded = !isExpanded
    setIsExpanded(newExpanded)
    onToggleExpand(newExpanded)
  }, [isExpanded, onToggleExpand])

  const eventResults = useMemo(() => {
    return (event?.results || []).map((result, index) => {
      const mappedResult = {
        ...result,
        round: result.round?.toUpperCase() || result?.fullMatchUp?.roundName?.toUpperCase() || '-',
        partner: result.partnerId,
        opponents: combineStringsCommaDelimited<string>(result.opponentId, result.opponentPartnerId),
        score: result.scoreString || convertToTitleCase(result.matchUpStatus),
        won: result.matchWon ? t('w') : t('l')
      }
  
      // Append event points to the first item
      if (index === 0) {
        mappedResult.points = 
          event?.originalPoints?.singlesPoints || 0 + 
          (event?.originalPoints?.processedDoubles || event?.originalPoints?.doublesPoints) || 0
      }
  
      return mappedResult
    })
  }, [event, t])

  const cols: DataCols<any> = useMemo(() => {
    return [
      { key: 'round', title: t('round'), getValue: m => m.round || '-' },
      {
        key: 'partner',
        title: t('partner'),
        getValue: m => protectFromUndefined(m.partner) || '-',
        hidden: !isDoublesList
      },
      {
        key: 'opponents',
        title: isDoublesList ? t('opponents') : t('opponent'),
        getValue: m => protectFromUndefined(m.opponents) || '-'
      },
      { key: 'win-lose', title: t('win lose'), getValue: m => m.won || '-' },
      {
        key: 'results',
        title: t('results'),
        getValue: m => m.score || '-'
      },
      {
        key: 'bonus',
        title: t('bonus'),
        getValue: m => {
          if (m.matchWon) {
            return m.bonusPointsWinValue || '-'
          }

          return '-'
        },
        hidden: !showBonusPoints
      },
      {
        key: 'points',
        title: t('points'),
        getValue: m => m.points || '-'
      }
    ]
  }, [isDoublesList, showBonusPoints, t])

  const totalProcessedPoints = useMemo(() =>
    event?.points?.processedSingles || 0 +
    event?.points?.processedDoubles || 0 +
    event?.points?.processedParticipation || 0 +
    event?.points?.processedBonus || 0
  , [event?.points])

  const totalEventPoints = useMemo(() =>
    event?.points?.singles || 0 +
    event?.points?.doubles || 0 +
    event?.points?.participation || 0 +
    event?.points?.bonus || 0
  , [event?.points])

  const totalPoints = totalProcessedPoints || totalEventPoints || 0

  return (
    <CustomGrid container spacing={{margins: { lg: 'top' }}} className={styles.accordionContainer}>
      <Accordion expanded={isExpanded} handleChange={handleToggleExpand}>
        <AccordionSummary>
          <Grid container justifyContent="space-between" className={styles.tableHeader}>
            <Grid>
              <Body size="lg" bold spacing={{ margins: { xxs: 'left' } }}>
                {title}
              </Body>
              <Body size="md" spacing={{ margins: { xxs: ['top', 'left'] } }}>
                {description}
              </Body>
            </Grid>
          </Grid>
          <CustomGrid container spacing={{margins: { md: 'top' }}} justifyContent='flex-end'>
            <CustomGrid
              spacing={{ margins: { lg: 'left' } }}
              className={styles.pointsContainer}
              hide={!event?.ageCategory?.maximumAge}
            >
              <span className={styles.totalPoints}>{event?.points?.bonus || event?.originalPoints?.bonusPoints || 0}</span>
              <p className={styles.points}>{t('bonus points').toUpperCase()}</p>
            </CustomGrid>
            <CustomGrid
              spacing={{ margins: { lg: 'left' } }}
              className={styles.pointsContainer}
              hide={event?.ageCategory?.maximumAge}
            >
              <span className={styles.totalPoints}>{event?.points?.participation || event?.originalPoints?.participationPoints || 0}</span>
              <p className={styles.points}>{t('participation points').toUpperCase()}</p>
            </CustomGrid>
            <CustomGrid
              spacing={{ margins: { lg: 'left' } }}
              className={styles.pointsContainer}
            >
              <span className={styles.totalPoints}>{event?.originalPoints?.singlesPoints + event?.originalPoints?.doublesPoints ?? 0}</span>
              <p className={styles.points}>{t('event points').toUpperCase()}</p>
            </CustomGrid>
            <CustomGrid
              spacing={{ margins: { lg: 'left' } }}
              className={styles.pointsContainer}
              hidden={event?.notFromList}
            >
              <Icon name="sm-tick" spacing={{ margins: { xxs: 'right' } }} />
              <span className={styles.totalPoints}>{totalPoints}</span>
              <p className={styles.points}>{t('list points').toUpperCase()}</p>
            </CustomGrid>
            {/* 
              Adding this in as we want the tooltip in the future to give context as to why the list points don't equal the event points
              this is common in lists where percentages of points from other divisions can contribute to the players ranking.
            */}
            <CustomGrid
              spacing={{ margins: { sm: 'left' } }}
              className={styles.pointsContainer}
              hidden={true}
            >
              <Tooltip
                placement="top"
                title={
                  <Grid container direction="column">
                    <Body size="xs" color={Body.color.WHITE} spacing={{ margins: { xxs: 'top' } }}>
                      {t('processed points')}
                    </Body>
                  </Grid>
                }
              >
                <div>
                  <Icon name="md-tooltip-annotation" />
                </div>
              </Tooltip>
            </CustomGrid>
          </CustomGrid>
        </AccordionSummary>
        <AccordionDetails>
          <AdminTable 
            columns={cols}
            data={eventResults}
            spacing={{ margins: { xs: 'top' } }}
            fixedWidth
          />
        </AccordionDetails>
      </Accordion>
    </CustomGrid>
  )
}

export default RankingPlayHistoryItemAccordian