// extracted by mini-css-extract-plugin
export var bodyBase = "ita-player-profile-header-module--body-base--koUUg ita-player-profile-header-module--site-font--XTlIw";
export var bodyLarge = "ita-player-profile-header-module--body-large--xkQIM ita-player-profile-header-module--body-base--koUUg ita-player-profile-header-module--site-font--XTlIw";
export var bodyLargeBold = "ita-player-profile-header-module--body-large-bold--WN6N+ ita-player-profile-header-module--body-base--koUUg ita-player-profile-header-module--site-font--XTlIw";
export var bodyRegular = "ita-player-profile-header-module--body-regular--sRn2W ita-player-profile-header-module--body-base--koUUg ita-player-profile-header-module--site-font--XTlIw";
export var bodyRegularBold = "ita-player-profile-header-module--body-regular-bold--Hd4Id ita-player-profile-header-module--body-base--koUUg ita-player-profile-header-module--site-font--XTlIw";
export var bodySmall = "ita-player-profile-header-module--body-small--+V5NL ita-player-profile-header-module--body-base--koUUg ita-player-profile-header-module--site-font--XTlIw";
export var bodySmallBold = "ita-player-profile-header-module--body-small-bold--M+oi+ ita-player-profile-header-module--body-base--koUUg ita-player-profile-header-module--site-font--XTlIw";
export var borderTop = "ita-player-profile-header-module--border-top--RLnRh";
export var bottomRow = "ita-player-profile-header-module--bottomRow--m6fmI";
export var breakWordContainer = "ita-player-profile-header-module--break-word-container--4YWcD";
export var buttonIconBase = "ita-player-profile-header-module--button-icon-base--dhrGI";
export var clickLink = "ita-player-profile-header-module--click-link--WbYDT";
export var closeButtonContainer = "ita-player-profile-header-module--close-button-container--JxOOG";
export var detailsBottom = "ita-player-profile-header-module--detailsBottom--S1JDI";
export var detailsTop = "ita-player-profile-header-module--detailsTop--9dqwN";
export var divider = "ita-player-profile-header-module--divider--6CRNe";
export var dropdownBase = "ita-player-profile-header-module--dropdown-base--wxTH1";
export var dropdownSelectBase = "ita-player-profile-header-module--dropdown-select-base--5r9S1 ita-player-profile-header-module--text-input--uFV3a";
export var duplicate = "ita-player-profile-header-module--duplicate--lN-co";
export var flexCol = "ita-player-profile-header-module--flex-col--DqovN";
export var formErrorMessage = "ita-player-profile-header-module--form-error-message--429bB";
export var h3 = "ita-player-profile-header-module--h3--4xyL2";
export var h4 = "ita-player-profile-header-module--h4--mt+BF";
export var headerContainer = "ita-player-profile-header-module--headerContainer--orynZ";
export var hoverLink = "ita-player-profile-header-module--hover-link--TQxwp";
export var hoverRow = "ita-player-profile-header-module--hover-row--CaPyH";
export var image = "ita-player-profile-header-module--image--b3fY0";
export var imageText = "ita-player-profile-header-module--image-text--QQtqN";
export var membershipContainer = "ita-player-profile-header-module--membership-container--6iJSD ita-player-profile-header-module--flex-col--DqovN ita-player-profile-header-module--primary-border--pkR2R";
export var membershipHeader = "ita-player-profile-header-module--membership-header--ZaoGO";
export var membershipHeading = "ita-player-profile-header-module--membership-heading--RWIyK";
export var membershipLabel = "ita-player-profile-header-module--membership-label--DVswp";
export var moreFiltersBorderClass = "ita-player-profile-header-module--more-filters-border-class--zAWQr";
export var pageBg = "ita-player-profile-header-module--page-bg--j42yO";
export var pointer = "ita-player-profile-header-module--pointer--F4V+k";
export var primaryBorder = "ita-player-profile-header-module--primary-border--pkR2R";
export var row = "ita-player-profile-header-module--row--B4eUs";
export var shadowBoxLight = "ita-player-profile-header-module--shadow-box-light--0MQPn";
export var siteFont = "ita-player-profile-header-module--site-font--XTlIw";
export var slideDownAndFade = "ita-player-profile-header-module--slideDownAndFade--Ivs9s";
export var slideLeftAndFade = "ita-player-profile-header-module--slideLeftAndFade--Onlqg";
export var slideRightAndFade = "ita-player-profile-header-module--slideRightAndFade--K+Gpp";
export var slideUpAndFade = "ita-player-profile-header-module--slideUpAndFade--9yEhb";
export var stat = "ita-player-profile-header-module--stat--95jFq";
export var statWtn = "ita-player-profile-header-module--stat-wtn--REn0G";
export var statusDecoration = "ita-player-profile-header-module--status-decoration--LuM2X";
export var tag = "ita-player-profile-header-module--tag--P44Uw";
export var textInput = "ita-player-profile-header-module--text-input--uFV3a";
export var textInverted = "ita-player-profile-header-module--text-inverted--r4+FX";
export var textMediumDark = "ita-player-profile-header-module--text-medium-dark--x257E";
export var tooltipFont = "ita-player-profile-header-module--tooltipFont--kyMgu";
export var unstyledButton = "ita-player-profile-header-module--unstyled-button--9y6pr";
export var userProfile = "ita-player-profile-header-module--user-profile--bfb+P";
export var wtnRating = "ita-player-profile-header-module--wtnRating--Lxdob";