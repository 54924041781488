// extracted by mini-css-extract-plugin
export var at = "dual-match-module--at--Gtq++";
export var bodyBase = "dual-match-module--body-base--l+qW0 dual-match-module--site-font--LBdZu";
export var bodyLarge = "dual-match-module--body-large--drkFG dual-match-module--body-base--l+qW0 dual-match-module--site-font--LBdZu";
export var bodyLargeBold = "dual-match-module--body-large-bold--1ZQl3 dual-match-module--body-base--l+qW0 dual-match-module--site-font--LBdZu";
export var bodyRegular = "dual-match-module--body-regular--SX-W3 dual-match-module--body-base--l+qW0 dual-match-module--site-font--LBdZu";
export var bodyRegularBold = "dual-match-module--body-regular-bold--vQaS+ dual-match-module--body-base--l+qW0 dual-match-module--site-font--LBdZu";
export var bodySmall = "dual-match-module--body-small--z5LUm dual-match-module--body-base--l+qW0 dual-match-module--site-font--LBdZu";
export var bodySmallBold = "dual-match-module--body-small-bold--g1bn3 dual-match-module--body-base--l+qW0 dual-match-module--site-font--LBdZu";
export var borderTop = "dual-match-module--border-top--fzdjr";
export var box = "dual-match-module--box--hX93h";
export var breakWordContainer = "dual-match-module--break-word-container--rupOW";
export var buttonIconBase = "dual-match-module--button-icon-base--ZSPPU";
export var clickLink = "dual-match-module--click-link--KLZTl";
export var dropdownBase = "dual-match-module--dropdown-base--zvhxc";
export var dropdownSelectBase = "dual-match-module--dropdown-select-base--naDbf dual-match-module--text-input--+OQTs";
export var flexCol = "dual-match-module--flex-col--inC23";
export var formErrorMessage = "dual-match-module--form-error-message--UbZ7O";
export var h3 = "dual-match-module--h3--My7aI";
export var h4 = "dual-match-module--h4--tEq8X";
export var hoverLink = "dual-match-module--hover-link--OvzZu";
export var hoverRow = "dual-match-module--hover-row--CpxdL";
export var label = "dual-match-module--label--Cffuu";
export var logo = "dual-match-module--logo--y49oq";
export var membershipContainer = "dual-match-module--membership-container--ZBvZ3 dual-match-module--flex-col--inC23 dual-match-module--primary-border--dXAPM";
export var membershipHeader = "dual-match-module--membership-header--VboJ1";
export var membershipHeading = "dual-match-module--membership-heading--8T7hL";
export var membershipLabel = "dual-match-module--membership-label--RhmnH";
export var moreFiltersBorderClass = "dual-match-module--more-filters-border-class--OwP-T";
export var opponentNameAndLogo = "dual-match-module--opponentNameAndLogo--qddvq";
export var pageBg = "dual-match-module--page-bg--PjYtq";
export var pointer = "dual-match-module--pointer--kmqjT";
export var primaryBorder = "dual-match-module--primary-border--dXAPM";
export var shadowBoxLight = "dual-match-module--shadow-box-light--qMEXa";
export var siteFont = "dual-match-module--site-font--LBdZu";
export var slideDownAndFade = "dual-match-module--slideDownAndFade--BCEbm";
export var slideLeftAndFade = "dual-match-module--slideLeftAndFade--NcnGf";
export var slideRightAndFade = "dual-match-module--slideRightAndFade--MjkZe";
export var slideUpAndFade = "dual-match-module--slideUpAndFade--paEzN";
export var statusDecoration = "dual-match-module--status-decoration--OA+iw";
export var textInput = "dual-match-module--text-input--+OQTs";
export var textInverted = "dual-match-module--text-inverted--wdgoG";
export var textMediumDark = "dual-match-module--text-medium-dark--23JI4";
export var tooltipFont = "dual-match-module--tooltipFont--jCDEg";
export var unstyledButton = "dual-match-module--unstyled-button--xmC7c";
export var vs = "dual-match-module--vs--JtwAl";