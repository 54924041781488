import React from 'react'
import { usePrivateRoute } from 'src/utils/auth'
import { Router, RouteComponentProps } from '@reach/router'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import ItaPlayerProfile from 'src/components/player-profile-ita/ita-player-profile'

const PlayerProfilePage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/players">
      <PlayerProfileRoute path="/:id" />
    </Router>
  )
}

interface PlayerProfileRouteProps extends RouteComponentProps {
  id?: string
}

const PlayerProfileRoute: React.FC<PlayerProfileRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Player Profile" />
      <ItaPlayerProfile id={id} />
    </Layout>
  )
}

export default PlayerProfilePage
