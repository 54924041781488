import graphql from 'graphql-tag'

export const GET_PLAYER_RANK_LISTS = graphql`
  query Ranklists(
    $sort: RankListSort
    $ranklistFilter: RankListFilterInput
    $pageArgs: PaginationArgs
    $itemFilter: RankListItemFilterInput
    $itemPageArgs: PaginationArgs!
  ) {
    ranklists(sort: $sort, ranklistFilter: $ranklistFilter, pageArgs: $pageArgs) {
      id
      ageRestriction
      matchFormat
      matchFormatType
      playerLevel
      playerType
      gender
      publishDate
      listType
      visible
      updatedAt
      createdAt
      dateRange {
        start
        end
      }
      region
      divisionType
      familyCategory
      rankingItemsPaginated(itemFilter: $itemFilter, itemPageArgs:$itemPageArgs) {
        items {
          id
          rank
          participants {
            itemId
            name
          }
          wins {
            singles
            doubles
            total
          }
          losses {
            singles
            doubles
            total
          }
          points {
            singles
            doubles
            bonus
            total
          }
        }
      }
    }
  }
`

export const GET_PLAYERS = graphql`
  query GetPlayers(
    $limit: Int
    $skip: Int
    $filter: FilterInput
    $idArray: [String]
    $sort: [PlayerSort]
  ) {
    players(limit: $limit, skip: $skip, filter: $filter, idArray: $idArray, sort: $sort) {
      id
      tennisID
      passportGivenName
      passportFamilyName
    }
  }
`

export const GET_PLAYER = graphql`
  query GetPlayer($id: String!) {
    player(id: $id) {
      id
      tennisID
      passportGivenName
      passportFamilyName
    }
  }
`
