// extracted by mini-css-extract-plugin
export var bodyBase = "player-tabs-module--body-base--1Ium3 player-tabs-module--site-font--Zljp0";
export var bodyLarge = "player-tabs-module--body-large--1mKfD player-tabs-module--body-base--1Ium3 player-tabs-module--site-font--Zljp0";
export var bodyLargeBold = "player-tabs-module--body-large-bold--8bhNV player-tabs-module--body-base--1Ium3 player-tabs-module--site-font--Zljp0";
export var bodyRegular = "player-tabs-module--body-regular--eQbUn player-tabs-module--body-base--1Ium3 player-tabs-module--site-font--Zljp0";
export var bodyRegularBold = "player-tabs-module--body-regular-bold--+LXHo player-tabs-module--body-base--1Ium3 player-tabs-module--site-font--Zljp0";
export var bodySmall = "player-tabs-module--body-small--woiJk player-tabs-module--body-base--1Ium3 player-tabs-module--site-font--Zljp0";
export var bodySmallBold = "player-tabs-module--body-small-bold--7kCDP player-tabs-module--body-base--1Ium3 player-tabs-module--site-font--Zljp0";
export var borderTop = "player-tabs-module--border-top--vMCVY";
export var breakWordContainer = "player-tabs-module--break-word-container--mxodS";
export var buttonIconBase = "player-tabs-module--button-icon-base--ZAhL4";
export var clickLink = "player-tabs-module--click-link--5nlKg";
export var dropdownBase = "player-tabs-module--dropdown-base--EsWMa";
export var dropdownSelectBase = "player-tabs-module--dropdown-select-base--rQyIt player-tabs-module--text-input--Jfww8";
export var flexCol = "player-tabs-module--flex-col--Vlnb-";
export var formErrorMessage = "player-tabs-module--form-error-message--oF456";
export var h3 = "player-tabs-module--h3--MXZny";
export var h4 = "player-tabs-module--h4--Pma6L";
export var hoverLink = "player-tabs-module--hover-link--q4P5v";
export var hoverRow = "player-tabs-module--hover-row--TZkqt";
export var indicator = "player-tabs-module--indicator--RJiVb";
export var membershipContainer = "player-tabs-module--membership-container--Za2Xv player-tabs-module--flex-col--Vlnb- player-tabs-module--primary-border--M2G2w";
export var membershipHeader = "player-tabs-module--membership-header--c-VB9";
export var membershipHeading = "player-tabs-module--membership-heading--t9fKD";
export var membershipLabel = "player-tabs-module--membership-label--gxMVL";
export var moreFiltersBorderClass = "player-tabs-module--more-filters-border-class--Ro+LE";
export var pageBg = "player-tabs-module--page-bg--GWEhu";
export var pointer = "player-tabs-module--pointer--Y1lwg";
export var primaryBorder = "player-tabs-module--primary-border--M2G2w";
export var selected = "player-tabs-module--selected--3hA66";
export var shadowBoxLight = "player-tabs-module--shadow-box-light--1HW0L";
export var siteFont = "player-tabs-module--site-font--Zljp0";
export var slideDownAndFade = "player-tabs-module--slideDownAndFade--g3WG9";
export var slideLeftAndFade = "player-tabs-module--slideLeftAndFade--fknOB";
export var slideRightAndFade = "player-tabs-module--slideRightAndFade--afvU3";
export var slideUpAndFade = "player-tabs-module--slideUpAndFade--++TmC";
export var statusDecoration = "player-tabs-module--status-decoration--5HsHA";
export var tabPanel = "player-tabs-module--tabPanel--zFqWG";
export var tabStyle = "player-tabs-module--tabStyle--uteXP player-tabs-module--body-regular--eQbUn player-tabs-module--body-base--1Ium3 player-tabs-module--site-font--Zljp0";
export var tabsWrapper = "player-tabs-module--tabsWrapper--wPIiX";
export var textInput = "player-tabs-module--text-input--Jfww8";
export var textInverted = "player-tabs-module--text-inverted--aLOWn";
export var textMediumDark = "player-tabs-module--text-medium-dark--MIKqU";
export var tooltipFont = "player-tabs-module--tooltipFont--IltgV";
export var unstyledButton = "player-tabs-module--unstyled-button--G7h4W";