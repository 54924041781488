import { useCallback } from 'react'
import { DivisionTypeEnum, ListTypeEnum, PlayerTypeEnum } from 'src/graphql-types/globalRankingTypes'
import { useCategoryOptions } from 'src/utils/helper/rankings'
import { FormatOptionsEnum } from './getRankListFilters'
import { getAdultFormat } from './getAdultFormatOptions'
import { TFunction } from 'react-i18next'
import { generateRanklistName } from 'src/utils/generate-ranklist-name/generate-ranklist-name'
import useDateTime from 'src/utils/helper/useDateTime'
import { RankListFilters } from '../players-rankings/players-rankings'

const useListTypeOptions = (playerType: PlayerTypeEnum, t: TFunction) => {
  const listTypeOptionsMap = {
    [PlayerTypeEnum.ADULT]: [
      { value: '', label: t('any list type') },
      { value: ListTypeEnum.STANDING, label: t('standing') },
      { value: ListTypeEnum.YEAR_END, label: t('year end') },
    ],
    [PlayerTypeEnum.JUNIOR]: [
      { value: '', label: t('any list type') },
      {
        value: ListTypeEnum.STANDING,
        label: t('standing')
      },
      {
        value: ListTypeEnum.SEEDING,
        label: t('seeding')
      },
      {
        value: ListTypeEnum.BONUS_POINTS,
        label: t('bonus points')
      },
      {
        value: ListTypeEnum.YEAR_END,
        label: t('year end')
      },
      {
        value: ListTypeEnum.QUOTA,
        label: t('quota list')
      },
      {
        value: ListTypeEnum.L2_QUALIFIER,
        label: t('level 2 qualifier')
      },
      {
        value: ListTypeEnum.DIRECT_ACCEPTANCE,
        label: t('direct acceptance')
      }
    ],
    [PlayerTypeEnum.FAMILY]: [
      { value: '', label: t('any list type') },
      { value: ListTypeEnum.STANDING, label: t('standing') },
      { value: ListTypeEnum.YEAR_END, label: t('year end') },
    ],
    [PlayerTypeEnum.WHEELCHAIR]: [
      { value: '', label: t('any list type') },
      { value: ListTypeEnum.STANDING, label: t('standing') },
      { value: ListTypeEnum.YEAR_END, label: t('year end') },
    ],
  }
  return listTypeOptionsMap[playerType]
}

const useMatchFormatOptions = (playerType: PlayerTypeEnum, divisionType: DivisionTypeEnum, t: TFunction) => {
  const formatOptionsMap = {
    [PlayerTypeEnum.ADULT]: getAdultFormat(divisionType, t),
    [PlayerTypeEnum.JUNIOR]: [
      { value: '', label: t('any format') },
      { value: FormatOptionsEnum.SINGLES, label: t('singles') },
      { value: FormatOptionsEnum.DOUBLES, label: t('doubles') },
      { value: FormatOptionsEnum.COMBINED, label: t('combined') },
    ],
    [PlayerTypeEnum.FAMILY]: [
      { value: '', label: t('any format') },
      { value: FormatOptionsEnum.TEAM_DOUBLES, label: t('team doubles') },
    ],
    [PlayerTypeEnum.WHEELCHAIR]: [
      { value: '', label: t('any format') },
      { value: FormatOptionsEnum.SINGLES, label: t('singles') },
      { value: FormatOptionsEnum.INDIVIDUAL_DOUBLES, label: t('individual doubles') },
    ],
  }
  return formatOptionsMap[playerType]
}

const useRankingListOptions = (rankListCandidates: any, t: TFunction) => {
  // Generate unique rank list names
  const uniqueRankListNames = [...new Set(rankListCandidates.map((candidate) => generateRanklistName(candidate, t)))]

  return [
    { value: '', label: t('any ranking list') },
    ...uniqueRankListNames.map((name) => ({
      value: name,
      label: name,
    })) 
  ]
}

const useRankingListRunDateOptions = (rankListCandidates: any, selectedName: string, t: TFunction) => {
  const { formatDate } = useDateTime(
    undefined, 
    { 
      format: { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric', 
      }
    }
  )
  return rankListCandidates
    .filter((candidate) => {
      const name = generateRanklistName(candidate, t)
      return name === selectedName
    })
    .map((candidate) => ({
      value: candidate.updatedAt,
      label: formatDate(new Date(candidate.updatedAt)),
    }))
    .sort((a, b) => a.value - b.value)
}

const usePlayerRankingFilters = (
  filters: RankListFilters, 
  setFilters: (filterUpdate: any) => void, 
  t: TFunction, 
  rankListCandidates: any, 
  setSelectedList: (list: any) => void
) => {
  const playerType = filters?.playerType ?? PlayerTypeEnum.ADULT
  const divisionType = filters?.divisionType ?? DivisionTypeEnum.AGE

  return useCallback(() => {
    const handleRankingListRunDateChange = (option) => {
      setFilters({ ...filters, rankingListRunDate: option.value })
      const selectedList = rankListCandidates.find(
        (candidate) =>
          generateRanklistName(candidate, t) === filters.rankingList &&
          candidate.updatedAt === option.value
      )
      setSelectedList(selectedList)
    }
    return [
      {
        id: 'playerType',
        options: useCategoryOptions(),
        onSelect: (option) => setFilters({ 
          ...filters, 
          playerType: option.value, 
          listType: '',
          matchFormat: '',
          rankingList: '',
          rankingListRunDate: '',
        }),
      },
      {
        id: 'listType',
        options: useListTypeOptions(playerType as PlayerTypeEnum, t),
        onSelect: (option) => setFilters({ ...filters, listType: option.value }),
        props: playerType === '' ? { disabled: true } : {},
      },
      {
        id: 'matchFormat',
        options: useMatchFormatOptions(playerType as PlayerTypeEnum, divisionType as DivisionTypeEnum, t),
        onSelect: (option) => setFilters({ ...filters, matchFormat: option.value }),
        props: playerType === '' ? { disabled: true } : {},
      },
      {
        id: 'rankingList',
        options: useRankingListOptions(rankListCandidates, t),
        onSelect: (option) => setFilters({ ...filters, rankingList: option.value }),
        props: !rankListCandidates?.length ? { disabled: true } : {},
      },
      {
        id: 'rankingListRunDate',
        options: useRankingListRunDateOptions(rankListCandidates, filters.rankingList as string, t),
        onSelect: handleRankingListRunDateChange,
        props: !rankListCandidates?.length || !filters.rankingList ? { disabled: true } : {},
      },
    ]
  }, [playerType, t, divisionType, rankListCandidates, filters, setFilters, setSelectedList])
}


export default usePlayerRankingFilters