// extracted by mini-css-extract-plugin
export var actionContainer = "suspension-player-module--action-container--xGNyn";
export var addIcon = "suspension-player-module--add-icon--A-e0N";
export var barColorGreen = "suspension-player-module--bar-color-green--SmbdY";
export var barColorSuspended = "suspension-player-module--bar-color-suspended--L6GOP";
export var barColorWarning = "suspension-player-module--bar-color-warning--87Aqu";
export var bodyBase = "suspension-player-module--body-base--bIMuL suspension-player-module--site-font--UXMT0";
export var bodyLarge = "suspension-player-module--body-large--ofmzT suspension-player-module--body-base--bIMuL suspension-player-module--site-font--UXMT0";
export var bodyLargeBold = "suspension-player-module--body-large-bold--ULIvp suspension-player-module--body-base--bIMuL suspension-player-module--site-font--UXMT0";
export var bodyRegular = "suspension-player-module--body-regular--BE6y3 suspension-player-module--body-base--bIMuL suspension-player-module--site-font--UXMT0";
export var bodyRegularBold = "suspension-player-module--body-regular-bold--xSv-Q suspension-player-module--body-base--bIMuL suspension-player-module--site-font--UXMT0";
export var bodySmall = "suspension-player-module--body-small--GpGQi suspension-player-module--body-base--bIMuL suspension-player-module--site-font--UXMT0";
export var bodySmallBold = "suspension-player-module--body-small-bold--NOLsj suspension-player-module--body-base--bIMuL suspension-player-module--site-font--UXMT0";
export var borderTop = "suspension-player-module--border-top--WKBQv";
export var breakWordContainer = "suspension-player-module--break-word-container--853zo";
export var buttonContainer = "suspension-player-module--button-container--McIuT";
export var buttonIconBase = "suspension-player-module--button-icon-base--LJUsb";
export var buttons = "suspension-player-module--buttons--mmO5A";
export var clickLink = "suspension-player-module--click-link--3GdYU";
export var dropdownBase = "suspension-player-module--dropdown-base--PDB0D";
export var dropdownSelectBase = "suspension-player-module--dropdown-select-base--3KJGB suspension-player-module--text-input--bAPR3";
export var flexCol = "suspension-player-module--flex-col--oHkBn";
export var formErrorMessage = "suspension-player-module--form-error-message--4Skku";
export var h3 = "suspension-player-module--h3--gTY1B";
export var h4 = "suspension-player-module--h4--4cvUY";
export var hoverLink = "suspension-player-module--hover-link--0KFO+";
export var hoverRow = "suspension-player-module--hover-row--whRUZ";
export var linearProgress = "suspension-player-module--linear-progress---P4B4";
export var membershipContainer = "suspension-player-module--membership-container--nJzq9 suspension-player-module--flex-col--oHkBn suspension-player-module--primary-border--DtFRD";
export var membershipHeader = "suspension-player-module--membership-header--dvdbv";
export var membershipHeading = "suspension-player-module--membership-heading--a5Yd7";
export var membershipLabel = "suspension-player-module--membership-label--8ImVV";
export var moreFiltersBorderClass = "suspension-player-module--more-filters-border-class--LFbMv";
export var pageBg = "suspension-player-module--page-bg--ARrpV";
export var pointer = "suspension-player-module--pointer--xORH+";
export var points = "suspension-player-module--points--6FIfO";
export var primaryBorder = "suspension-player-module--primary-border--DtFRD";
export var shadowBoxLight = "suspension-player-module--shadow-box-light--ax5gQ";
export var siteFont = "suspension-player-module--site-font--UXMT0";
export var slideDownAndFade = "suspension-player-module--slideDownAndFade--scxj9";
export var slideLeftAndFade = "suspension-player-module--slideLeftAndFade--Neblf";
export var slideRightAndFade = "suspension-player-module--slideRightAndFade--FFN-k";
export var slideUpAndFade = "suspension-player-module--slideUpAndFade--0U5al";
export var spacing = "suspension-player-module--spacing--RByVN";
export var statusDecoration = "suspension-player-module--status-decoration--0QYSQ";
export var suspensionContainer = "suspension-player-module--suspension-container--Ga3Si";
export var textInput = "suspension-player-module--text-input--bAPR3";
export var textInverted = "suspension-player-module--text-inverted--jCyAL";
export var textMediumDark = "suspension-player-module--text-medium-dark--6OoGW";
export var tooltipFont = "suspension-player-module--tooltipFont--0+56N";
export var unstyledButton = "suspension-player-module--unstyled-button--YbQnG";