// extracted by mini-css-extract-plugin
export var arrowIcon = "player-ranking-module--arrow-icon--t17JB";
export var bodyBase = "player-ranking-module--body-base---9w1c player-ranking-module--site-font--Ox2EZ";
export var bodyLarge = "player-ranking-module--body-large--L4Fc0 player-ranking-module--body-base---9w1c player-ranking-module--site-font--Ox2EZ";
export var bodyLargeBold = "player-ranking-module--body-large-bold--wcNj5 player-ranking-module--body-base---9w1c player-ranking-module--site-font--Ox2EZ";
export var bodyRegular = "player-ranking-module--body-regular--8HZBY player-ranking-module--body-base---9w1c player-ranking-module--site-font--Ox2EZ";
export var bodyRegularBold = "player-ranking-module--body-regular-bold--LTn29 player-ranking-module--body-base---9w1c player-ranking-module--site-font--Ox2EZ";
export var bodySmall = "player-ranking-module--body-small--vZmRP player-ranking-module--body-base---9w1c player-ranking-module--site-font--Ox2EZ";
export var bodySmallBold = "player-ranking-module--body-small-bold--bg-Gu player-ranking-module--body-base---9w1c player-ranking-module--site-font--Ox2EZ";
export var borderSeparator = "player-ranking-module--border-separator--iN4ZE";
export var borderTop = "player-ranking-module--border-top--pACoW";
export var breakWordContainer = "player-ranking-module--break-word-container--dPU0T";
export var buttonIconBase = "player-ranking-module--button-icon-base--J+k81";
export var clickLink = "player-ranking-module--click-link--d9F6e";
export var dropdownBase = "player-ranking-module--dropdown-base--8gIU2";
export var dropdownSelectBase = "player-ranking-module--dropdown-select-base--lqN2i player-ranking-module--text-input--rqcGr";
export var flexCol = "player-ranking-module--flex-col--Raux9";
export var formErrorMessage = "player-ranking-module--form-error-message--x9ZHK";
export var h3 = "player-ranking-module--h3--3BsIq";
export var h4 = "player-ranking-module--h4--VScrQ";
export var hoverLink = "player-ranking-module--hover-link--1YrpD";
export var hoverRow = "player-ranking-module--hover-row--8Snaq";
export var membershipContainer = "player-ranking-module--membership-container--AeRfa player-ranking-module--flex-col--Raux9 player-ranking-module--primary-border--n6yDM";
export var membershipHeader = "player-ranking-module--membership-header--NKytk";
export var membershipHeading = "player-ranking-module--membership-heading--re58-";
export var membershipLabel = "player-ranking-module--membership-label--QTKMs";
export var moreFiltersBorderClass = "player-ranking-module--more-filters-border-class--X5kbZ";
export var pageBg = "player-ranking-module--page-bg--IT20s";
export var pointer = "player-ranking-module--pointer--Z7z5W";
export var points = "player-ranking-module--points--8im4C";
export var pointsContainer = "player-ranking-module--points-container--AyFKB";
export var primaryBorder = "player-ranking-module--primary-border--n6yDM";
export var ranklistBaseInfo = "player-ranking-module--ranklist-base-info--1L11+ player-ranking-module--primary-border--n6yDM";
export var ranklistBaseItem = "player-ranking-module--ranklist-base-item--rSPL4";
export var ranklistItemLabel = "player-ranking-module--ranklist-item-label--acRSY";
export var ranklistItemValue = "player-ranking-module--ranklist-item-value--0ifzC";
export var shadowBoxLight = "player-ranking-module--shadow-box-light--gKZHQ";
export var siteFont = "player-ranking-module--site-font--Ox2EZ";
export var slideDownAndFade = "player-ranking-module--slideDownAndFade--l6He5";
export var slideLeftAndFade = "player-ranking-module--slideLeftAndFade--txzon";
export var slideRightAndFade = "player-ranking-module--slideRightAndFade--DeQHr";
export var slideUpAndFade = "player-ranking-module--slideUpAndFade--Ev9MQ";
export var statusDecoration = "player-ranking-module--status-decoration--zPbV5";
export var tableHeader = "player-ranking-module--table-header--JpJF9";
export var textInput = "player-ranking-module--text-input--rqcGr";
export var textInverted = "player-ranking-module--text-inverted--o0THn";
export var textMediumDark = "player-ranking-module--text-medium-dark--8RWnR";
export var tickIcon = "player-ranking-module--tick-icon--88cuk";
export var tooltipFont = "player-ranking-module--tooltipFont--BF52i";
export var totalPoints = "player-ranking-module--total-points--Jrv6M player-ranking-module--body-base---9w1c player-ranking-module--site-font--Ox2EZ";
export var unstyledButton = "player-ranking-module--unstyled-button--UVf1m";