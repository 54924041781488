import { MatchFormatTypeEnum } from 'src/graphql-types/globalRankingTypes'
import { MatchFormatEnum, PlayerTypeEnum, RankListGenderEnum } from 'src/graphql-types/globalUstaTypes'

export enum FormatOptionsEnum {
  SINGLES = 'SINGLES',
  DOUBLES = 'DOUBLES',
  INDIVIDUAL_DOUBLES = 'INDIVIDUAL DOUBLES',
  TEAM_DOUBLES = 'TEAM DOUBLES',
  MIXED_IND_DOUBLES = 'MIXED INDIVIDUAL DOUBLES',
  COMBINED = 'COMBINED'
}

const getFormatFilter = (category: PlayerTypeEnum, format: FormatOptionsEnum) => {
  if (category === PlayerTypeEnum.ADULT) {
    switch (format) {
      case FormatOptionsEnum.SINGLES:
        return { matchFormat: MatchFormatEnum.SINGLES }
      case FormatOptionsEnum.INDIVIDUAL_DOUBLES:
        return {
          matchFormat: MatchFormatEnum.DOUBLES,
          matchFormatType: MatchFormatTypeEnum.INDIVIDUAL,
          genderModifier: null
        }
      case FormatOptionsEnum.TEAM_DOUBLES:
        return { matchFormat: MatchFormatEnum.DOUBLES, matchFormatType: MatchFormatTypeEnum.TEAM }
      case FormatOptionsEnum.MIXED_IND_DOUBLES:
        return {
          matchFormat: MatchFormatEnum.DOUBLES,
          matchFormatType: MatchFormatTypeEnum.INDIVIDUAL,
          gender: RankListGenderEnum.X
        }
      default:
        return {}
    }
  }

  if (category === PlayerTypeEnum.JUNIOR) {
    switch (format) {
      case FormatOptionsEnum.SINGLES:
        return { matchFormat: MatchFormatEnum.SINGLES }
      case FormatOptionsEnum.DOUBLES:
        return {
          matchFormat: MatchFormatEnum.DOUBLES
        }
      case FormatOptionsEnum.COMBINED:
        return { matchFormat: MatchFormatEnum.COMBINED }
      default:
        return {}
    }
  }

  if (category === PlayerTypeEnum.WHEELCHAIR) {
    switch (format) {
      case FormatOptionsEnum.SINGLES:
        return { matchFormat: MatchFormatEnum.SINGLES, matchFormatType: null }
      case FormatOptionsEnum.INDIVIDUAL_DOUBLES:
        return {
          matchFormat: MatchFormatEnum.DOUBLES,
          matchFormatType: MatchFormatTypeEnum.INDIVIDUAL,
          genderModifier: null
        }
      default:
        return {}
    }
  }

  if (category === PlayerTypeEnum.FAMILY) {
    return { matchFormat: MatchFormatEnum.DOUBLES, matchFormatType: MatchFormatTypeEnum.TEAM }
  }
}

const getRanklistFilters = (filters: any) => {
  const { category, format, listType, publishStatus, division, gender, section } = filters
  const isVisible = publishStatus === 'PUBLISHED'
  const formatFilter = getFormatFilter(category as PlayerTypeEnum, format as FormatOptionsEnum)
  const isMixedGender = formatFilter?.gender === RankListGenderEnum.X

  let rFilters = {}

  if (division && division !== 'NONE') {
    if (category === PlayerTypeEnum.FAMILY) {
      rFilters = { ...rFilters, familyCategory: division }
    } else if (category === PlayerTypeEnum.WHEELCHAIR) {
      rFilters = { ...rFilters, divisionType: division }
    } else {
      rFilters = { ...rFilters, divisionType: division }
    }
  }
  if (category) rFilters = { ...rFilters, playerType: category }
  if (format) {
    rFilters = {
      ...rFilters,
      ...formatFilter
    }
  }
  if (listType) rFilters = { ...rFilters, listType }
  if (section) rFilters = { ...rFilters, region: section }
  if (publishStatus) rFilters = { ...rFilters, latestListVisible: isVisible }
  if (gender) {
    rFilters = { ...rFilters, [isMixedGender ? 'genderModifier' : 'gender']: gender }
  }
  return rFilters
}

export default getRanklistFilters