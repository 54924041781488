import React from 'react'
import * as styles from './player-ranking.module.less'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { CustomGrid } from 'src/components/custom-grid/custom-grid'
import { Grid } from '@material-ui/core'
import Spinner from 'src/components/spinner/spinner'
import { ApolloError } from '@apollo/client'
import { TD_GetRankList_td_rankList_rankingItems_items } from 'src/graphql-types/TD_GetRankList'

interface RankListBaseItemProps {
  label: string
  value?: number | null
  border?: boolean
}

const RankListBaseItem: React.FC<RankListBaseItemProps> = ({ 
  label, 
  value, 
  border = false 
}) => {
  return (
    <Grid
      container
      className={cx(styles.rankListBaseItem, { [styles.borderSeparator]: border })}
      direction="column"
    >
      <p className={styles.rankListItemLabel}>{label}</p>
      <p className={styles.rankListItemValue}>{value}</p>
    </Grid>
  )
}

interface PlayerRankingInfoHeaderProps {
  rankList?: TD_GetRankList_td_rankList_rankingItems_items | null
  loading: boolean
  error?: ApolloError
}

const PlayerRankingInfoHeader: React.FC<PlayerRankingInfoHeaderProps> = ({ 
  rankList,
  loading, 
  error 
}) => {
  const { t } = useTranslation()
  const { rank, points } = rankList ?? {}
  const { singles, doubles, bonus, participation, total } = points ?? {}
  return (
    loading ? (
      <Spinner /> 
    ) : (  
      <CustomGrid container className={styles.rankListBaseInfo}>
        <Grid item xs>
          <RankListBaseItem label={t('rank')} value={rank} border />
        </Grid>
        { singles ? (
          <Grid item xs>
            <RankListBaseItem label={t('singles points')} value={singles} border />
          </Grid>
        ) : null
        }
        { doubles ? (
          <Grid item xs>
            <RankListBaseItem label={t('doubles points')} value={doubles} border />
          </Grid>
        ) : null
        }
        { bonus ? (
          <Grid item xs>
            <RankListBaseItem label={t('bonus points')} value={bonus} border />
          </Grid>
        ) : null
        }
        { participation ? (
          <Grid item xs>
            <RankListBaseItem label={t('participation points')} value={participation} border />
          </Grid>
        ) : null
        }
        <Grid item xs>
          <RankListBaseItem label={t('Total Points')} value={total} />
        </Grid>
      </CustomGrid>
    )
  )
}

export default PlayerRankingInfoHeader